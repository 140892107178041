var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('abp-tabs',{attrs:{"tabs":_vm.tabs,"disabled":_vm.disabled,"saveState":_vm.saveState,"stateName":_vm.stateName},scopedSlots:_vm._u([{key:"form",fn:function(){return [(_vm.formModel && _vm.formDataReady)?_c('abp-simple-form',{attrs:{"model":_vm.formModel,"title":_vm.formTitle},on:{"submit":function($event){return _vm.submit(true)}},scopedSlots:_vm._u([{key:"after-title",fn:function(){return [_c('search-in-egrul',{attrs:{"isUL":_vm.isEdit ? (_vm.isUL ? true : false) : null},on:{"input":function($event){return _vm.fromEgrul($event)}}})]},proxy:true},{key:"after-fields",fn:function(){return [(_vm.showGroups && !_vm.miniForm)?_c('abp-groups',{attrs:{"table":_vm.table,"id":_vm.id}}):_vm._e()]},proxy:true}],null,false,3806060099),model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}}):_vm._e()]},proxy:true},{key:"employees",fn:function(){return [_c('abp-form-sub-table',{key:("st_employees_" + _vm.id),attrs:{"table":"sotrudniks","keyModel":[
          {
            employeable: {
              employeable_type: 'App\\Kontragent',
              employeable_id: _vm.id,
            },
          } ]}})]},proxy:true},{key:"rs",fn:function(){return [_c('abp-form-sub-table',{key:("st_rs_" + _vm.id),attrs:{"table":"rs","keyModel":[
          {
            rs_table: {
              rs_table_type: 'App\\Kontragent',
              rs_table_id: _vm.id,
            },
          } ]}})]},proxy:true},{key:"contracts",fn:function(){return [_c('abp-form-sub-table',{key:("contracts_" + _vm.id),attrs:{"table":"contracts","keyModel":[
          {
            contractable: {
              contractable_type: 'App\\Kontragent',
              contractable_id: _vm.id,
            },
          } ]}})]},proxy:true},{key:"after",fn:function(){return [_vm._t("after",function(){return [_c('v-row',[(!_vm.miniForm)?_c('v-col',{staticClass:"d-flex align-end flex-column"},[_c('v-btn',{staticClass:"ma-4",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeAction.apply(null, arguments)}}},[_vm._v(" Закрыть ")])],1):_vm._e()],1)]})]},proxy:true}],null,true),model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }